<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="80px">
      <el-form-item label="银行名称" prop="bankName" :rules="rules">
        <el-input style="width:300px;" v-model="formData.bankName" size="small" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="logo" prop="logo" :rules="rules">
        <FileUpload :name.sync="formData.logo" :url="formData.logoUrl"></FileUpload>
      </el-form-item>
      <el-form-item label="背景图" prop="bgImage" :rules="rules" class="card-bg">
        <FileUpload :name.sync="formData.bgImage" :url="formData.bgImageUrl"></FileUpload>
        <!-- <div class="tips">（建议尺寸：335px * 120px）</div> -->
      </el-form-item>
      <el-form-item label="排序">
        <el-input style="width:300px;" v-model="formData.sort" size="small" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addBankAPI, editBankAPI, getBankDetailAPI } from './api'
import FileUpload from '@/components/file-upload/index.vue'
export default {
  name: 'AddOrEdit',
  components: { FileUpload },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        bankName: '',
        logo: '',
        bgImage: '',
        sort: 0
      }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getBankDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editBankAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addBankAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getBankDetail() {
      this.formData = await getBankDetailAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .tips {
    color: #909399;
    line-height: 0;
    font-size: 13px;
    
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
  }
  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }
  .card-bg {
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 335px;
      height: 120px;
      line-height: 120px;
      text-align: center;
    }
    .avatar {
      width: 335px;
      height: 120px;
      display: block;
    }
  }
}
</style>
